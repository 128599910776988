<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center"
    style="height: 100vh !important"
  >
    <div
      style="height: 95vh !important"
      class="text-center d-flex flex-1 justify-content-center align-items-center"
      v-if="IsLoading"
    >
      <!-- <b-spinner variant="success" type="grow"></b-spinner>  -->
      <laoder></laoder>
    </div>
    <div
      class="d-flex d-lg-none flex-column justify-content-center align-items-center"
    >
      <b-img
        :src="appLogoImage"
        alt="logo"
        style="width: 50px"
        class="img-fluid"
      />
      <h3 class="text-center">{{ appName }}</h3>
    </div>
    <div class="auth-v1" v-if="!IsLoading">
      <div class="card d-flex m-md-1">
        <div class="d-flex p-1 w-100 container-div">
          <div class="d-xl-flex flex-column d-none">
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <b-img
                :src="appLogoImage"
                alt="logo"
                style="width: 50px"
                class="img-fluid"
              />
              <h3 class="text-center">{{ appName }}</h3>
            </div>
            <div>
              <img
                src="@/assets/images/login.png"
                style="width: 100%; max-width: 400px"
              />
              <h1 class="text-center" style="font-size: 35px; margin-top: 10px">
                Welcome back!
              </h1>
              <h3 class="text-center" style="margin-top: 20px; font-size: 13pt">
                Please login to continue
              </h3>
            </div>
          </div>

          <div class="d-flex flex-column justify-content-between p-md-1">
            <div class="d-flex align-items-center justify-content-center">
              <h2 class="brand-text text-primary d-inline-block">Login</h2>
              <!-- <b-link variant="primary" to="/email-invite">
                <feather-icon class="cursor-pointer" icon="ArrowRightIcon" />
                Send Invitation To get login access
              </b-link> -->
            </div>
            <!--* form -->
            <validation-observer
              style="height: 100%"
              class="d-flex flex-1 flex-column justify-content-xl-center"
              ref="loginForm"
              #default="{ invalid }"
            >
              <b-form
                style="height: 70%"
                class="d-flex flex-1 flex-column justify-content-between"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group label-for="email" label="Email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      name="login-email"
                      :state="errors.length > 0 || loginError ? false : null"
                      placeholder="johndoe@example.com"
                      autofocus
                      @click="loginError = ''"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group label-for="password">
                  <div class="d-flex justify-content-between">
                    <label for="password">Password</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        autocomplete="off"
                        id="password"
                        v-model="password"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 || loginError ? false : null"
                        name="login-password"
                        placeholder="Password"
                        @click="loginError = ''"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <small class="text-danger">{{ loginError }}</small>
                <!-- submit button -->
                <b-link :to="{ name: 'email-verification' }">
                  <small style="float: right">Forgot Password?</small>
                </b-link>
                <b-button
                  variant="primary"
                  type="submit"
                  block
                  :disabled="invalid"
                >
                  Sign in
                </b-button>
                <span class="d-flex align-items-center justify-content-center">
                  <span class="mr-1">New on our platform ?</span>
                  <b-link :to="{ name: 'users-register' }">
                    Create an account
                  </b-link>
                </span>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BImg,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import { required, regex } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import Laoder from "@/@core/layouts/components/Gen/Laoder.vue";
import { checkUserPlan } from "@/helper/helpers";
import { getAccessTokenFromCookie } from "@/auth/utils";
export default {
  components: {
    // BSV
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BImg,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    Laoder,
  },
  mixins: [togglePasswordVisibility],
  data() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      loginError: "",
      email: "",
      password: "",
      appLogoImage,
      appName,
      // validation rules
      required,
      regex,
      IsLoading: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async login() {
      this.IsLoading = true;
      await axios
        .post("/login", {
          email: this.email,
          password: this.password,
        })
        .then(({ data }) => {
          if (data.status == "success") {
            const { userData, accessToken, result: userAccess } = data;
            this.setAccessTokenCookie(accessToken, userAccess, userData);
            this.$ability.update([
              {
                action: "manage",
                subject: "all",
              },
            ]);
            if (userData.role === "admin") {
              this.$router.push("admin-dash").then(() => {
                this.showToast(
                  "success",
                  "CoffeeIcon",
                  `Welcome ${userData.first_name + " " + userData.last_name}`,
                  `You have successfully logged in as ${userData.role}. Now you can start to explore!`
                );
          this.IsLoading = false;
              });
            }
            if (userData.role === "user") {
              const fetchData = async () => {
                this.$store.dispatch("updateplanCheck", await checkUserPlan());
              };
              fetchData();
              this.$router.push("dashboard").then(() => {
                this.showToast(
                  "success",
                  "CoffeeIcon",
                  `Welcome ${userData.first_name + " " + userData.last_name}`,
                  `You have successfully logged in as ${userData.role}. Now you can start to explore!`
                );
                
          this.IsLoading = false;
              });
            }
          }
        })
        .catch((error) => {
          this.password = "";
          this.IsLoading = false;
          const { data } = error;
          this.loginError = data.error;
          this.showToast(
            "danger",
            "AlertCircleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
    setAccessTokenCookie(token, userAccess, userData) {
      const data = {
        accessToken: token,
        userAccess: userAccess,
        userData: userData,
      };
      const cookieValue = JSON.stringify(data);

      var expires = new Date();
      expires.setMonth(expires.getMonth() + 1);
      expires = expires.toUTCString();
      document.cookie = `auth_data=${cookieValue}; expires=${expires}; path=/; Secure; SameSite=Strict;`;
    },
  },
  beforeRouteEnter(to, from, next) {
    const data = getAccessTokenFromCookie();
    if (data) {
      const role = data[2].role;
      if (role === "admin") {
        next("/admin-dash");
      } else {
        next("dashboard");
      }
    } else {
      next(true);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

@media (max-width: 1200px) {
  .login-link {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
.toast-body {
  background-color: #79512b !important;
  color: white;
  font-weight: bold;
}
</style>
