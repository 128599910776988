<template>
  <div>
    <div class="loading-container">
      <div class="loading-logo">
        <img :src="appLogoImage" alt="Logo" />
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { $themeConfig } from "@themeConfig";
  export default {
    data() {
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
  }

</script>
<style>
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.loading-container {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  height: 90px;
}
.loading .effects {
  transition: all 0.3s ease;
}

.loading .effect-1,
.loading .effect-2,
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-left: 4px solid  #f9b316;
  border-radius: 50%;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  animation: rotateOpacity 1s ease infinite 0.2s;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}


</style>